import "./message.css";

//ui development components
import SearchIcon from "@mui/icons-material/Search";
import SortIcon from "@mui/icons-material/Sort";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import DescriptionIcon from "@mui/icons-material/Description";
import ImageIcon from "@mui/icons-material/Image";
import SendIcon from "@mui/icons-material/Send";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import moment from "moment";
import dayjs from "dayjs"; // Import Day.js
import Qr from "../Qr/Qr";
import LogoutIcon from "@mui/icons-material/Logout";

import upload_img from "../../assets/images/icons/upload_img.png";

//services
import useMessageServices from "../../services/messageServices";
import { MEDIA_URL } from "../../api/axios";
import { useState, useEffect, useRef } from "react";

const Message = () => {
  const [checkBox, setCheckBox] = useState([]);
  const [lead, setLead] = useState([]);
  const [isSorted, setIsSorted] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [messageData, setMessageData] = useState([]); // State for message data
  const [newMessage, setNewMessage] = useState(""); // State for new message input
  const [selectedLeads, setSelectedLeads] = useState([]); // State for selected leads
  const [mediaMessage, setMediaMessage] = useState({
    mobile_number: "",
    lead_id: "",
    message: "",
    document: null,
    document_type: "",
  });
  const [previewUrl, setPreviewUrl] = useState(null); // State for preview URL
  const [selectedTab, setSelectedTab] = useState("Chat"); // State for selected tab

  const [fileType, setFileType] = useState(""); // State to track the file type

  const [open, setOpen] = useState(false);
  const [statusResponse, setStatusResponse] = useState({});

  const chatBoxRef = useRef(null);

  const handleOpen = (type) => {
    setFileType(type);
    setOpen(true);
  };

  useEffect(() => {
    const chatBox = chatBoxRef.current;
    if (chatBox) {
      chatBox.scrollTop = chatBox.scrollHeight;
    }
  }, [messageData]);

  const handleClose = () => setOpen(false);

  const {
    getSingleMessage,
    postSingleMessage,
    postMediaMessage,
    getLeads,
    getStatus,
    userLogout,
  } = useMessageServices(); // 1) Get Leads

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
    outline: "none", // Remove the default focus outline
    width: "50%",
  };

  const checkLinkedStatus = async () => {
    try {
      const response = await getStatus();
      console.log("statss", response);
      setStatusResponse(response.data);
    } catch (e) {
      console.log("check status error", e);
    }
  };

  useEffect(() => {
    checkLinkedStatus();
  }, []);

  // 2) Get leads Function

  const getLead = async () => {
    const response = await getLeads();
    console.log("leadssss", response);
    setLead(response.data);
  };

  useEffect(() => {
    getLead();
  }, []);

  useEffect(() => {
    setSelectedLeads([]);
    setCheckBox([]);
  }, [selectedTab]);

  useEffect(() => {
    setMediaMessage((prev) => ({ ...prev, message: newMessage }));
  }, [newMessage]);

  // 3) Inserting selected leads to checkbox and updating selectedLeads state
  const handleCheckbox = (leadId) => {
    let tempList = [...checkBox];
    if (tempList.includes(leadId)) {
      tempList = tempList.filter((i) => i !== leadId);
    } else {
      tempList = [...tempList, leadId];
    }
    setCheckBox([...tempList]);

    const selected = lead.filter((lead_data) =>
      tempList.includes(lead_data.id)
    );
    setSelectedLeads(selected);
  };

  console.log("checkbox", checkBox);

  // 4) Sorting leads
  const handleSort = () => {
    const sortedLeads = [...lead].sort((a, b) => {
      return isSorted
        ? b.name.localeCompare(a.name)
        : a.name.localeCompare(b.name);
    });
    setLead(sortedLeads);
    setIsSorted(!isSorted);
  };

  // 5) Handle Search
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredLeads = lead.filter((lead_data) =>
    lead_data.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  console.log("filtered", filteredLeads);

  console.log("selected", selectedLeads);

  // Handle new message input change
  const handleMessageChange = (e) => {
    setNewMessage(e.target.value);
    setMediaMessage({ ...mediaMessage, message: e.target.value });
  };

  // Handle send button click
  const handleSendMessage = async () => {
    try {
      if (selectedLeads.length === 1 && newMessage.trim()) {
        const lead = selectedLeads[0];
        const formattedMobile = `91${lead.mobile}`;
        const payload = {
          mobile_number: formattedMobile,
          message: newMessage,
          lead_id: lead.id,
          type: "TEXT",
        };
        await postSingleMessage(payload);
        setNewMessage("");
        getLead();
        fetchMessageData(formattedMobile); // Fetch updated messages after sending
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMessageData = async (mobile) => {
    try {
      console.log("Fetching messages with mobile:", mobile);
      const response = await getSingleMessage(mobile);
      if (response && response.data) {
        setMessageData(response.data);
      } else {
        console.log("No data received:", response);
        setMessageData([]);
      }
    } catch (e) {
      console.log("Error fetching message data:", e);
      setMessageData([]);
    }
  };

  useEffect(() => {
    if (selectedLeads.length === 1) {
      fetchMessageData(`91${selectedLeads[0].mobile}`);
    } else {
      setMessageData([]);
    }
  }, [selectedLeads]);

  console.log("history", messageData);

  const handleSendMediaMessage = async () => {
    if (selectedLeads.length === 1 && mediaMessage.document) {
      const lead = selectedLeads[0];
      const formattedMobile = `91${lead.mobile}`;
      const formData = new FormData();
      formData.append("mobile_number", formattedMobile);
      formData.append("message", mediaMessage.message);
      formData.append("lead_id", lead.id);
      formData.append("type", "MEDIA");
      formData.append("document", mediaMessage.document); // Ensure file is appended with a name
      formData.append("document_type", mediaMessage.document_type);

      try {
        const response = await postMediaMessage(formData);
        setNewMessage("");
        setMediaMessage({
          mobile_number: "",
          lead_id: "",
          document: null,
          message: "",
        }); // Clear the input fields
        fetchMessageData(formattedMobile);
        getLead();
      } catch (error) {
        console.error("Error sending media message:", error);
      }
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    let fileType = "";
    if (file.type.startsWith("image/")) {
      fileType = "IMAGE";
    } else if (
      file.type === "application/pdf" ||
      file.type.startsWith("application/vnd")
    ) {
      fileType = "DOC";
    } else if (file.type.startsWith("video/")) {
      fileType = "VIDEO";
    }
    setMediaMessage({
      ...mediaMessage,
      document: file,
      document_type: fileType,
    });
    setPreviewUrl(URL.createObjectURL(file)); // Set the preview URL
  };

  console.log("media", mediaMessage);

  //date filter
  const groupMessagesByDate = (messages) => {
    const groupedMessages = messages.reduce((acc, message) => {
      const date = moment(message.created_at).format("YYYY-MM-DD");
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(message);
      return acc;
    }, {});

    const formattedMessages = Object.keys(groupedMessages).map((date) => ({
      date: date,
      messages: groupedMessages[date],
    }));
    return formattedMessages;
  };

  const getMessageDateLabel = (date) => {
    const today = moment().startOf("day");
    const yesterday = moment().subtract(1, "days").startOf("day");
    const messageDate = moment(date).startOf("day");

    if (messageDate.isSame(today, "d")) {
      return "Today";
    } else if (messageDate.isSame(yesterday, "d")) {
      return "Yesterday";
    } else {
      return moment(date).format("MMMM Do, YYYY");
    }
  };

  const groupedMessages = groupMessagesByDate(messageData);

  const getFileName = (documentPath) => {
    const parts = documentPath.split("/");
    let txt = parts[parts.length - 1];
    let a = txt.split(".");
    a[0] = a[0].slice(0, 12);
    txt = a.join(".");
    console.log(txt);
    return txt;
  };

  const handleDocumentDownload = (documentPath) => {
    const documentUrl = MEDIA_URL + documentPath;
    const fileName = getFileName(documentPath); // Get the filename
    // Create a temporary link element
    const link = document.createElement("a");
    link.href = documentUrl;
    link.download = fileName; // Set the download attribute to prompt download
    link.target = "_blank"; // Open in a new tab
    // Append the link to the body
    document.body.appendChild(link);
    // Programmatically click the link
    link.click();
    // Remove the link from the document
    document.body.removeChild(link);
  };

  const getTimeLabel = (date) => {
    const today = moment().startOf("day");
    const yesterday = moment().subtract(1, "days").startOf("day");
    const messageDate = moment(date).startOf("day");
    if (messageDate.isSame(today, "d")) {
      return dayjs(date).format("h:mm A");
    } else if (messageDate.isSame(yesterday, "d")) {
      return "Yesterday";
    } else {
      return dayjs(date).format("DD-MM-YYYY");
    }
  };

  const handleQrResponse = (response) => {
    console.log("Received QR response in parent:", response);
    // Do something with the response in the parent
  };

  const onUserLogout = async () => {
    try {
      const response = await userLogout();
      console.log("logout response", response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="message-container row bg-white p-2 me-3">
        {statusResponse.connected == true ? (
          <>
            <div className="top-bar p-2 row d-flex align-items-center justify-content-between mb-2">
              <div className="bar-left d-flex align-items-center justify-content-start gap-2 col-4">
                <Avatar
                  src=""
                  alt="no img"
                  className="lead-avatar ms-3"
                ></Avatar>
                <h5 className="">{statusResponse.mobile_number}</h5>
              </div>
              <button
                className="btn btn-outline-dark col-1 col-2 me-3"
                style={{ backgroundColor: "#DED9FF" }}
                onClick={onUserLogout}
              >
                <LogoutIcon /> Logout
              </button>
            </div>
            <div className="col-3 col-4 mx-2 message-container-left me-4">
              <div>
                <div className="container-left-top py-4 px-3">
                  <div className="input-icon-wrapper">
                    <SearchIcon className="search-icon" />
                    <input
                      className="left-top-input w-100 py-2 text-white px-1 ps-5"
                      placeholder="Search"
                      value={searchTerm}
                      onChange={handleSearch}
                    />
                  </div>
                  <div className="tab-switch w-100 p-1 mt-2">
                    <button
                      className={`tab-button py-2 px-4 ${
                        selectedTab === "Chat" ? "active py-2 px-3" : ""
                      }`}
                      onClick={() => setSelectedTab("Chat")}
                    >
                      Chat
                    </button>
                    <button
                      className={`tab-button py-2 px-4 ${
                        selectedTab === "Broadcasts" ? "active py-2 px-3" : ""
                      }`}
                      onClick={() => setSelectedTab("Broadcasts")}
                    >
                      Broadcasts
                    </button>
                  </div>
                </div>
                <div className="container-left-bottom bg-white">
                  <div className="horizontal-line w-100"></div>
                  <div className="mt-3">
                    {selectedTab == "Chat" ? (
                      <>
                        <div className="user-list-bottom px-1">
                          {filteredLeads.length === 0 ? (
                            <div className="ps-2 my-2">
                              <p className="text-secondary m-0 p-0">
                                No leads found
                              </p>
                            </div>
                          ) : (
                            filteredLeads.map((lead_data) => {
                              return (
                                <div
                                  key={lead_data.id}
                                  className={`box w-100 ps-4 d-flex align-items-center justify-content-start gap-0 my-1 ${
                                    checkBox.includes(lead_data.id)
                                      ? "selected"
                                      : ""
                                  }`}
                                  onClick={() => handleCheckbox(lead_data.id)}
                                >
                                  <Avatar
                                    src={
                                      lead_data.image
                                        ? `${MEDIA_URL}${lead_data.image}`
                                        : null
                                    }
                                    alt={lead_data.name}
                                    className="lead-avatar"
                                  >
                                    {!lead_data.image &&
                                      lead_data.name.charAt(0).toUpperCase()}
                                  </Avatar>
                                  <div className="box-content d-flex flex-column align-items-start justify-content-start gap-1 ps-3">
                                    <h5 className="text-bold m-0 p-0">
                                      {lead_data.name}
                                    </h5>
                                    {lead_data.last_message && (
                                      <p className="lead-last-message m-0 p-0">
                                        {lead_data.last_message.message}
                                      </p>
                                    )}
                                  </div>
                                  {lead_data.last_message && (
                                    <p className="lead-last-message-time m-0 p-0">
                                      {getTimeLabel(
                                        lead_data.last_message.created_at
                                      )}
                                    </p>
                                  )}
                                </div>
                              );
                            })
                          )}
                        </div>
                      </>
                    ) : (
                      <>gg</>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-8 message-container-right">
              <div>
                <div className="container-right-top py-4 p-2 d-flex align-items-center">
                  {selectedLeads.length === 0 ? (
                    <div className="ps-2 my-2">
                      <p className="text-white m-0 p-0">No leads selected</p>
                    </div>
                  ) : selectedLeads.length === 1 ? (
                    <>
                      <div className="d-flex align-items-center gap-2 ps-2 justify-content-between w-100">
                        <div className="d-flex align-items-center gap-2">
                          <Avatar
                            key={selectedLeads[0].id}
                            alt={selectedLeads[0].name}
                            src={selectedLeads[0].image}
                            style={{ width: "50px", height: "50px" }}
                          />
                          <div className="">
                            <h3 className="text-white m-0 p-0">
                              {selectedLeads[0].name}
                            </h3>
                            <p className="text-white m-0 p-0 mt-1">
                              {selectedLeads[0].mobile}
                            </p>
                          </div>
                        </div>
                        <MoreVertIcon className="fs-2 text-white" />
                      </div>
                    </>
                  ) : (
                    <AvatarGroup max={3}>
                      {selectedLeads.map((lead_data) => (
                        <Avatar
                          key={lead_data.id}
                          alt={lead_data.name}
                          src={lead_data.image}
                        />
                      ))}
                    </AvatarGroup>
                  )}
                </div>
                <div className="container-right-bottom mt-2">
                  {selectedLeads.length === 0 ? (
                    <div className="ps-2 my-2">
                      <p className="text-dark m-0 p-0">No leads selected</p>
                    </div>
                  ) : selectedLeads.length === 1 ? (
                    <>
                      <div className="single-lead d-flex flex-column px-2">
                        <div className="chat-box mt-2 p-2" ref={chatBoxRef}>
                          {groupedMessages.length ? (
                            groupedMessages.map((group, index) => (
                              <div key={index}>
                                <p className="message-date text-center mt-2 m-auto w-25 py-1">
                                  {getMessageDateLabel(group.date)}
                                </p>
                                {group.messages.map((message, messageIndex) => (
                                  <div key={messageIndex}>
                                    {message.history.map(
                                      (historyItem, historyIndex) => (
                                        <div
                                          key={historyIndex}
                                          className="w-100 d-flex flex-column align-items-end justify-content-end"
                                        >
                                          {historyItem.log.map(
                                            (logItem, logIndex) => (
                                              <>
                                                <p
                                                  className={
                                                    logItem.message
                                                      ? "chat-message my-2 p-2"
                                                      : ""
                                                  }
                                                >
                                                  {logItem.message}
                                                </p>
                                                <div className="message-content">
                                                  {logItem.document ? (
                                                    <>
                                                      {logItem.document_type ===
                                                        "IMAGE" && (
                                                        <img
                                                          src={
                                                            logItem.document
                                                              ? MEDIA_URL +
                                                                logItem.document
                                                              : ""
                                                          }
                                                          alt=""
                                                          // crossOrigin="anonymous"
                                                          className={
                                                            logItem.document
                                                              ? "chat-image"
                                                              : ""
                                                          }
                                                        />
                                                      )}
                                                      {logItem.document_type ===
                                                        "DOC" && (
                                                        <div className="document-preview">
                                                          <DescriptionIcon className="fs-2" />
                                                          <div>
                                                            <a
                                                              href="#"
                                                              onClick={() =>
                                                                handleDocumentDownload(
                                                                  logItem.document
                                                                )
                                                              }
                                                            >
                                                              {getFileName(
                                                                logItem.document
                                                              )}
                                                            </a>
                                                          </div>
                                                        </div>
                                                      )}
                                                      {logItem.document_type ===
                                                        "VIDEO" && (
                                                        <video
                                                          src={
                                                            MEDIA_URL +
                                                            logItem.document
                                                          }
                                                          controls
                                                          className={
                                                            logItem.document
                                                              ? "chat-image"
                                                              : ""
                                                          }
                                                        />
                                                      )}
                                                    </>
                                                  ) : (
                                                    <p>{message.message}</p>
                                                  )}
                                                </div>
                                              </>
                                            )
                                          )}
                                        </div>
                                      )
                                    )}
                                  </div>
                                ))}
                              </div>
                            ))
                          ) : (
                            <p>No messages found</p>
                          )}
                        </div>
                        <div className="message-box p-2 d-flex align-items-center justify-content-center bg-white py-0 gap-3">
                          <div className="message-uploader p-2 d-flex align-items-center justify-content-center">
                            <DescriptionIcon
                              className="fs-2"
                              onClick={() => handleOpen("DOCUMENT")}
                            />
                          </div>
                          <div className="message-uploader p-2 d-flex align-items-center justify-content-center">
                            <ImageIcon
                              className={
                                mediaMessage.document
                                  ? "fs-2 text-success"
                                  : "fs-2"
                              }
                              onClick={() => handleOpen("IMAGE_VIDEO")} // Set file type to image/video
                            />
                            <Modal
                              open={open}
                              onClose={handleClose}
                              aria-labelledby="modal-modal-title"
                              aria-describedby="modal-modal-description"
                            >
                              <Box sx={style}>
                                <div className="row my-2 ms-3">
                                  <div className="lead-image-upload text-center p-4 row">
                                    <div className="mb-3">
                                      {previewUrl ? (
                                        <div className="preview">
                                          <Typography variant="body1">
                                            Preview:
                                          </Typography>
                                          {mediaMessage.document_type ===
                                            "IMAGE" && (
                                            <img
                                              src={previewUrl}
                                              alt="Preview"
                                              width="300px"
                                              height="300px"
                                            />
                                          )}
                                          {mediaMessage.document_type ===
                                            "DOC" && (
                                            <div className="document-preview d-flex align-items-center justify-content-center w-100">
                                              <DescriptionIcon className="fs-2" />
                                              <div className="">
                                                <a
                                                  href={
                                                    MEDIA_URL +
                                                    mediaMessage.document
                                                  }
                                                  download
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  {mediaMessage.document.name}
                                                </a>
                                              </div>
                                            </div>
                                          )}
                                          {mediaMessage.document_type ===
                                            "VIDEO" && (
                                            <video
                                              src={previewUrl}
                                              controls
                                              width="300px"
                                              height="300px"
                                            />
                                          )}
                                        </div>
                                      ) : (
                                        <img
                                          src={upload_img}
                                          alt="Upload placeholder"
                                          width="200px"
                                          height="200px"
                                        />
                                      )}
                                    </div>
                                    <div className="my-3">
                                      <div>
                                        <input
                                          id="leadImage"
                                          type="file"
                                          // accept="image/*,.pdf,.doc,.docx,.xlsx,.mp4,.mkv"
                                          accept={
                                            fileType === "DOCUMENT"
                                              ? ".pdf,.doc,.docx,.xlsx"
                                              : fileType === "IMAGE_VIDEO"
                                              ? "image/*,.mp4,.mkv"
                                              : "*/*" // fallback to accept all types
                                          }
                                          onChange={(e) => handleFileChange(e)}
                                          className="mt-2 d-none"
                                        />
                                        <label
                                          htmlFor="leadImage"
                                          className="col-6 btn btn-outline-primary browse-btn"
                                        >
                                          Browse Files
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Box>
                            </Modal>
                          </div>
                          <input
                            type="text"
                            className="message-text py-2 p-1"
                            value={newMessage}
                            onChange={handleMessageChange}
                            placeholder="Type Text Message"
                          />
                          <div
                            className="message-send d-flex align-items-center justify-content-center gap-3 p-2"
                            onClick={
                              mediaMessage.document
                                ? handleSendMediaMessage
                                : handleSendMessage
                            }
                          >
                            <SendIcon className="fs-4 text-white" />
                            <p className="fs-6 text-white">Send</p>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="multiple-lead"></div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <Qr
              mobileNumber={statusResponse.mobile_number}
              onResponse={handleQrResponse}
            />
          </>
        )}
      </div>
    </>
  );
};

export default Message;
