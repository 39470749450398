import './Event.css'
import { PiSealCheckFill } from "react-icons/pi";
import { FaArrowRight } from "react-icons/fa";
import { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import useEventServices from "../../services/eventsServices";
import { QrCodeScanner } from '@mui/icons-material';
// import QRScanner from './components/QRScanner'
import { TbGiftOff, TbGift } from "react-icons/tb";
import Swal from 'sweetalert2';


const Event = () => {
    const [show, setShow] = useState(false);
    const [users, setUsers] = useState([]);
    const [userDetails, setUserDetails] = useState({})
    const { getEventUsers, postGiftStatus } = useEventServices();

    const getUsersList = async () => {
        const response = await getEventUsers();
        if (response?.success) {
            setUsers(response.data);
        }
    };

    const changeGiftStatus = async (id) => {
        try{
            const response = await postGiftStatus(id);
            if (response?.success) {
                Swal.fire({
                    title: "Success",
                    text: response.message,
                    icon: "success"
                });
                getUsersList();
            }
        }catch(error) {
            console.log(error)
            Swal.fire({
                title: "Success",
                text: error.response.data.message,
                icon: "warning"
            });
        }
    };

    useEffect(()=>{
        getUsersList();
    },[])

    const getDate = (data) => {
        const date = new Date(data);
        const d = date.getDate();
        const m = date.getMonth() + 1;
        const y = date.getFullYear();
        const dateFormat = `${d}/${m}/${y}, ${date.toLocaleTimeString()}`;
        return dateFormat
    };
    
    
    return (
    <div className="m-1 users-list mt-5">
        {/* <div className="container">
            <QRScanner/>
        </div> */}
        <div className="row">
            <div className="col text-end">Total <span style={{fontWeight: "bold"}} >{users.length}</span> records</div>
        </div>
        <div className="row table-head bg-primary text-white p-3 rounded fw-bold ">
            <div className="col">Sl No</div>
            <div className="col-2">Name</div>
            <div className="col">Mobile</div>
            <div className="col-2">E-mail</div>
            <div className="col-2">Created</div>
            <div className="col">Gift Eligibility</div>
            <div className="col-2 align-items-center">Gift Name</div>
            <div className="col align-items-center">Status</div>
        </div>    
        {
            users.map((user, index)=>{
                return (
                    <div className={`row table-row p-3 rounded  shadow-sm my-2 ${user.eligible_for_gift && (user.gift_given ? 'gifted' : 'not-gifted')}`} key={user.id}>
                        <div className="col d-flex align-items-center text-center">{index + 1}</div>
                        <div className="col-2 d-flex align-items-center table-main-col overflow-scroll" onClick={
                            () => {
                                setUserDetails(users[index]); 
                                setShow(true)
                            }
                        }>
                            <a>{user.name}</a>
                            {/* {
                                user.is_verified && <span className='text-primary'><PiSealCheckFill className='fs-4 me-1'/></span> 
                            } */}
                        </div>
                        <div className="col d-flex align-items-center">{user.mobile?.length>10 && '+'}{user.mobile}</div>
                        <div className="col-2 d-flex align-items-center overflow-scroll">{user.email}</div>
                        <div className="col-2 d-flex align-items-center">{getDate(user.created_at)}</div>
                        <div className={`col d-flex align-items-center ${user.eligible_for_gift ? 'text-success' : 'text-danger'}`}>{user.eligible_for_gift ? 'Eligible' : 'Not Eligible '}</div>
                        <div className="col-2 d-flex align-items-center">{user.gift }</div>
                        <div className="col align-items-center">
                            {
                                user.eligible_for_gift &&
                                <button type="button" onClick={() => changeGiftStatus(user.id)} className={`btn fs-4 ${user.gift_given  ? 'text-success given' : 'text-danger not-given'}`}>
                                    {
                                    user.gift_given ? 
                                    <TbGift />  :
                                    <TbGiftOff />
                                    }
                                </button> 
                            }
                        </div>
                    </div>
                )
            })
        }
        <Modal
            show={show}
            onHide={() => setShow(false)}
            centered={true}
            size="md"
            contentClassName="lead-modal rounded-4 modal-content"
          >
            <Modal.Body className="px-3 py-4">
            <div className="user-details">
                <div className="heading mb-3 text-center fw-bold text-white bg-primary px-4 py-2 rounded"><h4>{userDetails.name}</h4></div>
                <div className="container px-4 py-2 shadow-sm ">
                    <div className="row mt-1">
                        <h5>Basic Details</h5>
                    </div>
                    <div className="my-1 mx-4">
                        <div className="row">
                            <div className="col-6">Name :</div>
                            <div className="col-6">{userDetails.name}</div>
                        </div>
                        <div className="row">
                            <div className="col-6">Mobile :</div>
                            <div className="col-6">{userDetails.mobile?.length>10 && '+'}{userDetails.mobile}</div>
                        </div>
                        <div className="row">
                            <div className="col-6">E-mail :</div>
                            <div className="col-6">{userDetails.email}</div>
                        </div>
                        <div className="row">
                            <div className="col-6">Group/Individual :</div>
                            <div className="col-6">{userDetails.is_group ? 'Group' : 'Individual'}</div>
                        </div>
                        {
                            userDetails.is_group &&
                                <div className="row">
                                    <div className="col-6">Members : </div>
                                    <div className="col-6">{userDetails.members_count}</div>
                                </div>
                        }
                        <div className="row">
                            <div className="col-6">Created :</div>
                            <div className="col-6">{getDate(userDetails.created_at)}</div>
                        </div>
                    </div>
                    {
                        userDetails.details &&
                        <>
                            <div className="row mt-4 fw-bold">
                                <h5>Additional Details</h5>
                            </div>
                            <div className="my-1 mx-4">
                                {
                                    Object.keys(userDetails.details).map((key, index)=>{
                                        return (
                                        <div className="row">
                                            <div className="col-6">{key} :</div>
                                            <div className="col-6">{ userDetails.details[key]}</div>
                                        </div>
                                        )
                                    })
                                }
                            </div>
                        </>
                    }
                    
                </div>
            </div>   
            <div className="row staff-btn-container">
              <div className="row col-md-6 mt-3">
                <div className="col-md-6"></div>
                <div className="col-md-6">
                  <button
                    type="button"
                    onClick={() => setShow(false)}
                    className="btn btn-outline-primary staff-btn col-12"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
            </Modal.Body>
        </Modal>
    </div>
  )
}

export default Event;