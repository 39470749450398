import "./qr.css";
import useMessageServices from "../../services/messageServices";
import { useState, useEffect } from "react";
import { QRCode, Space } from "antd";

const Qr = ({ mobileNumber, onResponse }) => {
  const [qrCodeValue, setQrCodeValue] = useState(""); // State to hold the QR code value
  const [instance, setInstance] = useState({
    instance_id: "",
  });

  const { getQRLink, postInstanceId } = useMessageServices();
  console.log("number", mobileNumber);

  useEffect(() => {
    if (mobileNumber) {
      getCode(mobileNumber);
    }
  }, [mobileNumber]);

  const getCode = async (mobile) => {
    try {
      const response = await getQRLink({ mobile_number: mobile });
      console.log("resp", response);
      if (response?.data?.qr_code?.base64) {
        const base64String = response.data.qr_code.base64;
        setQrCodeValue(base64String);
        onResponse(response);
      } else {
        console.log("QR code not found in the response");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onInputChange = (e) => {
    setInstance({ ...instance, instance_id: e.target.value });
  };

  const onButtonClick = async () => {
    try {
      const response = await postInstanceId(instance);
      console.log(response);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div className="qr-box row col-11 p-2 bg-white d-flex align-items-center justify-content-center">
        <div className="qr-left col-4 p-0 m-0 mx-0 px-0">
          <img
            src={qrCodeValue}
            alt=""
            width="60%"
            height="60%"
            className="qr-image"
          />
        </div>
        <div className="qr-right col-5 p-2">
          <table className="instructions-table">
            <tr className="no-border-top mb-3">
              <td className="col-text" colspan="2">
                <h3 className="mb-4">To Use WhatsApp on your CRM Account</h3>
              </td>
            </tr>
            <tr className="mb-2">
              <td className="col-number">1.</td>
              <td className="col-text">Open WhatsApp on your phone</td>
            </tr>
            <tr className="mb-2">
              <td className="col-number">2.</td>
              <td className="col-text">
                Tap Menu on Android, or Settings on iPhone
              </td>
            </tr>
            <tr className="mb-2">
              <td className="col-number">3.</td>
              <td className="col-text">
                Tap Linked devices and then Link a device
              </td>
            </tr>
            <tr className="mb-2">
              <td className="col-number">4.</td>
              <td className="col-text">
                Point your phone at this screen to capture the QR code
              </td>
            </tr>
            <tr className="mb-3">
              <td colspan="2" className="mb-5">
                <h5 className="fw-bold text-center pt-5 pb-5">OR</h5>
              </td>
            </tr>
            <tr className="no-border-bottom">
              <td colspan="2" className="align-left align-top">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Your instance ID"
                  onChange={(e) => {
                    onInputChange(e);
                  }}
                ></input>
                <button
                  className="link-btn btn btn-light w-100 py-3"
                  onClick={onButtonClick}
                >
                  Linked With Phone Number ?
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </>
  );
};

export default Qr;
