import useAxiosPrivate from "../hooks/useAxiosPrivate";


const useEventServices = () => {
    const axiosPrivate = useAxiosPrivate();
  
    const getEventUsers = async () => {
        const response = await axiosPrivate.get(
            "/events/users/"
        );
        return response.data;
    };

    const getEventWhatsapp = async () => {
        const response = await axiosPrivate.get("/events/whatsapp/config/")
        return response.data;
    }
    const postEventWhatsapp = async (data) => {
        const response = await axiosPrivate.post("/events/whatsapp/config/",data)
        return response.data;
    }

    const getEventConfig = async () => {
        const response = await axiosPrivate.get("/events/config/")
        return response.data;
    }

    const postEventConfig = async (data) => {
        const response = await axiosPrivate.post("/events/config/",data)
        return response.data;
    }

    const postEventGiftConfig = async (id,data) => {
        const response = await axiosPrivate.post(`/events/config/${id}/gifts/`,data)
        return response.data;
    }

    const postGiftStatus = async (id) => {
        const response = await axiosPrivate.post(`/events/user/${id}/gift_status/`)
        return response.data;
    }

    return {
        getEventUsers,
        getEventWhatsapp,
        postEventWhatsapp,
        getEventConfig,
        postEventConfig,
        postEventGiftConfig,
        postGiftStatus,
    };
};

export default useEventServices;
