import axios from "axios";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { TEST_URL } from "../api/axios";

const useMessageServices = () => {
  const axiosPrivate = useAxiosPrivate();

  //post new message
  const postSingleMessage = async (data) => {
    const response = await axiosPrivate.post("/message/direct_message/", data);
    return response.data;
  };

  //get Single User message
  // const getSingleMessage = async (data) => {
  //   const response = await axiosPrivate.get("/message/message_personal_chat?", {
  //     params: { ...data },
  //   });
  //   return response.data;
  // };

  const getQRLink = async (data) => {
    const response = await axiosPrivate.post("/message/create_instance/", data);
    return response.data;
  };

  const userLogout = async () => {
    const response = await axiosPrivate.post("/message/whatsapp_logout/");
    return response.data;
  };

  const getSingleMessage = async (data) => {
    const response = await axiosPrivate.get("/message/message_personal_chat/", {
      params: { mobile: data },
    });
    return response.data;
  };

  const getStatus = async (data) => {
    const response = await axiosPrivate.get("/message/check_status/");
    return response.data;
  };

  const getLeads = async (data) => {
    const response = await axiosPrivate.get("/message/message_home/");
    return response.data;
  };

  const postMediaMessage = async (data) => {
    const response = await axiosPrivate.post("/message/direct_message/", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  };

  const postInstanceId = async (data) => {
    const response = await axiosPrivate.post(
      "/messaage/create_instance/",
      data
    );
    return response.data;
  };


  // Bulk Whatsapp Message

  // GET
  const getChannels = async (data) => {
    const response = await axiosPrivate.get("/message/broadcast_home/");
    return response.data;
  };

  // POST
  // const postInstanceId = async (data) => {
  //   const response = await axiosPrivate.post(
  //     "/messaage/create_instance/",
  //     data
  //   );
  //   return response.data;
  // };

  // PUT
  // const putLeadDetails = async (id, data) => {
  //   const response = await axiosPrivate.put(`/lead/lead_details/${id}/`, data);
  //   return response.data;
  // };

  // DELETE
  // const deleteForm = async (id) => {
  //   const response = await axiosPrivate.delete(`/form_edit_delete/${id}/`);
  //   return response.data;
  // };

  return {
    getChannels,
    getLeads,
    getQRLink,
    getStatus,
    
    postSingleMessage,
    getSingleMessage,
    postMediaMessage,
    postInstanceId,
    
    userLogout,
  };
};

export default useMessageServices;
