import check_img from "../../../../../assets/images/icons/check_log.png"
import close_img from "../../../../../assets/images/icons/close_log.png"


const CompanyAddForm = ({
    newCompany,
    handleSubmit,
    handleChange,
    setOpenImageModal,
    imageName,
    handleCancel,
    companyId,
    edit
}) => {
    return (
        <form onSubmit={handleSubmit} className='company-form-body'>
            <div className="col-12 row mx-0">
                <div className="Add-company col-7">New company</div>
                {companyId&&
                    <div className="text-end col-5">
                        <span className="h5 fw-bold">ID : </span>
                        <span className="">{companyId}</span>
                    </div>
                }
            </div>
            <div className='company-row w-100'>
                <div className='company-form-input-cont'>Name*<input required value={newCompany.company_name} type='text' onChange={handleChange} placeholder='Name' name="company_name" className='form-control company-form-input' /></div>
                <div className='company-form-input-cont'>Mobile*<input required value={newCompany.mobile} type='number' onChange={handleChange} placeholder='1234567890' name="mobile" className='form-control company-form-input mobile' /></div>
                <div className='company-form-input-cont'>Email*<input required value={newCompany.email} type='email' onChange={handleChange} placeholder='abc@mail.com' name="email" className='form-control company-form-input' /></div>
            </div>
            <div className='company-row w-100'>
                <div className='company-form-input-cont'>Voxbay Pin*<input required value={newCompany.voxbay_pin} type='text' onChange={handleChange} placeholder='Voxbay Pin' name="voxbay_pin" className='form-control company-form-input' /></div>
                <div className='company-form-input-cont'>Voxbay UID*<input required value={newCompany.voxbay_uid} type='text' onChange={handleChange} placeholder='Voxbay uid' name="voxbay_uid" className='form-control company-form-input' /></div>
                <div className='company-form-input-cont'>Image*<input required type='text' value={imageName} onClick={() => setOpenImageModal(true)} readOnly placeholder='Select Image' name="image" className='company-form-input pointer' /></div>
            </div>
            <div className='w-100 d-flex gap-5 justify-content-center'>
                <div className='company-form-input-cont'>Vozpro Access Token<input value={newCompany.voz_access_token} type='text' onChange={handleChange} placeholder='Access Token' name="voz_access_token" className='form-control company-form-input' /></div>
                <div className='company-form-input-cont'>Staff Count*<input required value={newCompany.staff_count} type='number' onChange={handleChange} placeholder='Staff Count' name="staff_count" className='form-control company-form-input' /></div>
            </div>
            <div className='w-100 d-flex gap-3 justify-content-center'>
                <button type='submit' className='submit_btn'><img src={check_img} width={14} className='me-3' alt='button_check' />{edit?"Edit":"Submit"}</button>
                <div className='cancel_btn' onClick={handleCancel}>
                    <img src={close_img} width={14} className='me-3' alt='button_check' />
                    Cancel
                </div>
            </div>
        </form>
    )
}

export default CompanyAddForm