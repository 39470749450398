import '../../Admin.css'
import { useState } from 'react'
import { Form, Modal } from "react-bootstrap"
import upload_img from "../../../../assets/images/icons/upload_img.png"
// import check_img from "../../../../assets/images/icons/check_log.png"
// import close_img from "../../../../assets/images/icons/close_log.png"
import { Crop } from '../../../../components/imageCrop/Crop'
// import {TiTick} from 'react-icons/ti'
import useAdminServices from '../../../../services/adminServices'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import CompanyAddForm from './components/CompanyAddForm'
import { useEffect } from 'react'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'

const AdminCompanyForm = ({edit, data, setShowEdit, refresh}) =>{
    const [openImageModal,setOpenImageModal] = useState(false)
    const [showSubscription, setShowSubscription] = useState(false)
    const [subscriptionType, setSubscriptionType] = useState("Day")
    const [subscriptionCount, setSubscriptionCount] = useState(15)
    const [subscriptionStart, setSubscriptionStart] = useState(dayjs())
    const [result, setResult] = useState(null)
    const [srcImg, setSrcImg] = useState(null)
    const [imageName, setImageName] = useState("")
    const [imageData, setImageData] = useState("")
    const [companyId, setCompanyId] = useState("")
    const [companyUserId, setCompanyUserId] = useState("")
    const [newCompany, setNewCompany] = useState({
        voxbay_uid:'',
        voxbay_pin:'',
        company_name:'',
        mobile:'',
        email:'',
        id:'', 
        voz_access_token:'',
        staff_count:10

    })

    const {
        postCompany,
        putCompany,
        getIDConfig, 
        postSubscription
    } = useAdminServices()

    const navigate = useNavigate()

    useEffect(()=>{
        getID()
    },[])

    useEffect(()=>{
        if(data){ 
            const {vox_config,first_name, vozpro_config,...others} = data;
            let data2 = {...vox_config,...others,company_name:first_name, voz_access_token:vozpro_config}
            // console.log(data2)
            setNewCompany(data2)
        }

    },[data])

    const getID = async()=>{
        try{
            const response = await getIDConfig({type:'Company'})
            if(response?.success){
                setCompanyId(response?.data?.complete)
            }
        }catch{}
    }

    const handleClose = () =>{
        setResult("")
        setSrcImg("")
        setImageData("")
        setImageName("")
        setOpenImageModal(false)
    }

    const handleSubmit = (e) =>{
        e.preventDefault()
        setOpenImageModal(false)
    }

    const createCompany = async(e) =>{
        e.preventDefault()
        if(isNaN(newCompany.mobile) || newCompany.mobile.length < 10){
            Swal.fire("Enter Valid mobile number", "", "error")
            return 0
        }
        try{
            const data = new FormData()
            data.append("voxbay_uid",newCompany.voxbay_uid)
            data.append("voxbay_pin",newCompany.voxbay_pin)
            data.append("voz_access_token",newCompany.voz_access_token)
            data.append("staff_count",newCompany.staff_count)
            data.append("company_name",newCompany.company_name)
            data.append("mobile",newCompany.mobile)
            data.append("email",newCompany.email)
            
            if(!edit){
                data.append("image",result)
            }
            let res
            if(edit){
                res = await putCompany(newCompany.id,data)
            }else{
                res = await postCompany(data)
            }
            if(res.success){
                if(edit){
                    refresh()
                    Swal.fire("Success","Company updated successfully","success")
                    setShowEdit(false)
                }
                else{
                    setCompanyUserId(res?.data?.user?.id)
                    setShowSubscription(true)
                }
            }
            else
            Swal.fire("Failed",res?.message,"error")
        }catch(err){
            Swal.fire("Failed to create company","","error")
            console.log(err)
        }
    }

    const handleChange = (e) =>{
        setNewCompany((prev)=>({...prev,[e.target.name]:e.target.value}))
    }
    const handleDragOver = (event) => {
        event.preventDefault();
    }

    const handleSubscriptionSubmit = async(e) =>{
        e.preventDefault()
        try{
            const data=JSON.stringify({
                type:subscriptionType,
                count:subscriptionCount,
                start_date:new Date(subscriptionStart).toISOString()
            })
            const response = await postSubscription(companyUserId, data)
            if (response?.success ){
                handleSubscriptionClose()
            }
            else{
                Swal.fire('Error', response?.message, "error");
            }
        }catch(err){
            console.log(err)
        }
    }

    const handleSubscriptionClose = () => {
        Swal.fire("success", "Company Created Successfully", "success")
        setShowSubscription(false)
        navigate('/list-company')
    }

    const handleDrop = (event) => {
        event.preventDefault();
        var files = event.dataTransfer.files;
        var fileInput = event.target;
        fileInput.files = files;
        // console.log(event)
        handleImage(event);
    }

    const handleImage = (e) =>{
        if (!e.target.files || e.target.files.length === 0) {
          setImageData(undefined)
          return 0
        }
        setSrcImg(URL.createObjectURL(e.target.files[0]))
        setImageName(e.target.files[0].name)
        setImageData(e.target.files[0])
        setResult(e.target.files[0])
    }

    return(
        <div className="company-form-container pt-4">
           {edit?<Modal show={edit} size='xl' centered>
                <CompanyAddForm 
                    handleSubmit={createCompany}
                    {...{
                        newCompany,
                        handleChange,
                        imageName,
                        setOpenImageModal,
                        edit
                    }}
                    handleCancel={()=>setShowEdit(false)}
                />
            </Modal>
            :
            <CompanyAddForm 
                handleSubmit={createCompany}
                {...{
                    handleChange,
                    imageName,
                    setOpenImageModal,
                    companyId,
                    edit,
                    newCompany
                }}
                handleCancel={()=>navigate('/list-company')}
            />
            }
            <Modal
                size="lg"
                centered
                show={openImageModal}
                onHide={handleClose}
                contentClassName="lead-desc-modal px-5"
            >
                <Modal.Body>
                    <div className='mx-3'>
                        <Form onSubmit={handleSubmit}>
                            <div className="row my-2">
                                <div className="h4 fw-bolder">Update Profile Image</div>
                            </div>
                            <div className="row my-2">
                                <div onDragOver={(e) => handleDragOver(e)} onDrop={(e) => handleDrop(e)} className="lead-image-upload text-center p-4">
                                    {imageName?
                                    <>
                                    <Crop {...{setSrcImg,result,setResult,imageData,setImageData,srcImg}}/>
                                    </>:
                                    <>
                                        <div className="mb-3">
                                            <img className='uploadPreview' src={upload_img} alt="" />
                                        </div>
                                        <div className="">
                                            <div>Drag & Drop Files Here</div>
                                            <div>or</div>
                                        </div>
                                    </>
                                    }
                                    <div className="mt-3">
                                        <div>
                                            <label htmlFor='leadImage' className='col-6 btn btn-outline-primary browse-btn'>Browse Files</label>
                                            <input id='leadImage' onChange={(e) => handleImage(e)} hidden className='form-control' type="file" />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="row">
                                <div className="px-0    ">
                                    <div onClick={handleClose} className='btn btn-pill col-md-2 col-12 my-2 btn-outline-primary float-end'>Cancel</div>
                                    <button 
                                        onClick={handleSubmit} 
                                        className={
                                            window.innerWidth>768?
                                            `btn btn-pill col-md-2 col-12 my-2 btn-primary float-end mx-3 `:
                                            `btn btn-pill col-md-2 col-12 my-2 btn-primary float-end `
                                        }
                                    >Submit</button>
                                </div>
                            </div>
                        </Form >
                    </div >
                </Modal.Body>
            </Modal>
            <Modal
                size="lg"
                centered
                show={showSubscription}
                onHide={handleSubscriptionClose}
                contentClassName="lead-desc-modal px-5"
            >
                <Modal.Body>
                    <div className='mx-3'>
                        <Form onSubmit={handleSubscriptionSubmit}>
                            <div className="row my-2">
                                <div className="h4 fw-bolder">Company Subscription</div>
                            </div>
                            <div className="row my-2 justify-content-center">
                                <Form.Group className='col-md-6 col-12 my-2'>
                                    <Form.Label>Start Date</Form.Label>
                                    <DatePicker
                                        label={'Start Date'}
                                        className="reminder-datepicker form-control"
                                        onChange={(e) => setSubscriptionStart(new Date(e["$d"]))}
                                        format="DD / MMM / YYYY"
                                        defaultValue={subscriptionStart}
                                    />
                                </Form.Group>
                                <Form.Group className='col-md-6 col-12 my-2'>
                                    <Form.Label>Start Date</Form.Label>
                                    <Form.Select onChange={(e)=>setSubscriptionType(e.target.value)} value={subscriptionType}>
                                        <option value="Day">Day</option>
                                        <option value="Week">Week</option>
                                        <option value="Month">Month</option>
                                        <option value="Year">Year</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className='col-md-6 col-12 d-flex align-items-center my-2'>
                                    <Form.Control
                                        type='number'
                                        onChange={(e)=>setSubscriptionCount(e.target.value)}
                                        value={subscriptionCount}
                                    />
                                    {subscriptionType&&<div>&nbsp;&nbsp;{subscriptionCount>1?subscriptionType+"s":subscriptionType}</div>}
                                </Form.Group>
                            </div>
                            <div className="row">
                                <div className="px-0">
                                    <div onClick={handleSubscriptionClose} className='btn btn-pill col-md-2 col-12 my-2 btn-outline-primary float-end'>Skip</div>
                                    <button 
                                        onClick={handleSubscriptionSubmit} 
                                        className={
                                            window.innerWidth>768?
                                            `btn btn-pill col-md-2 col-12 my-2 btn-primary float-end mx-3 `:
                                            `btn btn-pill col-md-2 col-12 my-2 btn-primary float-end `
                                        }
                                    >Submit</button>
                                </div>
                            </div>
                        </Form >
                    </div >
                </Modal.Body>
            </Modal>
        </div>
    )
}


export default AdminCompanyForm